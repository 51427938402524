<template>
  <b-collapse
    tag="ul"
    :class="className"
    :visible="open"
    :id="idName"
    :accordion="accordianName"
  >
    <li
      v-for="(item, index) in items"
      :key="index"
      :class="
        !hideListMenuTitle
          ? 'p-0'
          : item.is_heading
          ? 'iq-menu-title'
          : activeLink(item) && item.children
          ? 'active'
          : activeLink(item)
          ? 'active'
          : ''
      "
    >
      <i v-if="item.is_heading && hideListMenuTitle" class="ri-subtract-line" />
      <span v-if="item.is_heading && hideListMenuTitle">{{
        $t(item.name)
      }}</span>
      <router-link
        :to="item.link"
        v-if="!item.is_heading"
        :class="
          `iq-waves-effect ${
            activeLink(item) && item.children
              ? 'active'
              : activeLink(item)
              ? 'active'
              : ''
          }`
        "
        v-b-toggle="item.name"
      >
        <i :class="item.icon" v-if="item.is_icon_class" />
        <template v-else v-html="item.icon"> </template>
        <span>{{ $t(item.name) }}</span>
        <i v-if="item.children" class="ri-arrow-right-s-line iq-arrow-right" />
        <small
          v-html="item.append"
          v-if="hideListMenuTitle"
          :class="item.append_class"
        />
      </router-link>
      <List
        class="custom-sidebar"
        v-if="item.children"
        :items="item.children"
        :sidebarGroupTitle="hideListMenuTitle"
        :open="
          item.link.name !== '' && activeLink(item) && item.children
            ? true
            : !!(item.link.name !== '' && activeLink(item))
        "
        :idName="item.name"
        :accordianName="`sidebar-accordion-${item.class_name}`"
        :className="`iq-submenu ${item.class_name}`"
      />
    </li>
  </b-collapse>
</template>
<script>
import List from './CollapseMenu'
import { core } from '../../../config/pluginInit'
import store from '@/store/index'
import { MENU_ROLE } from '@/config/featureStatus'
export default {
  name: 'List',
  props: {
    items: Array,
    className: { type: String, default: 'iq-menu w-100' },
    open: { type: Boolean, default: false },
    idName: { type: String, default: 'sidebar' },
    accordianName: { type: String, default: 'sidebar' },
    sidebarGroupTitle: { type: Boolean, default: true }
  },
  components: {
    List
  },
  computed: {
    hideListMenuTitle() {
      return this.sidebarGroupTitle
    }
  },
  mounted() {
    this.hakAkses()
  },
  methods: {
    activeLink(item) {
      return core.getActiveLink(item, this.$route.name)
    },
    hakAkses() {
      const idUnitKs = store.state.authService.idUnitKs
      // const menus = [
      //   'master.jenisPks', 'master.kategoriPks', 'master.subKategoriPks',
      //   'kerjasama.dokumentasiSk', 'kerjasama.suratTagihanBiayaPendidikan', 'kerjasama.serbagunaBilling',
      //   'kerjasama.flaggingBilingAdmisiMou', 'sidebar.laporan', 'sidebar.menuKhusus'
      // ]
      /** List menu yang tidak ada akses per role */
      // const notMenusDpks = [
      //   'kerjasama.entryBeasiswa', 'kerjasama.importMahasiswaBeasiswa', 'kerjasama.suratTagihanBiayaPendidikan', 'kerjasama.dokumentasiSk',
      //   'kerjasama.serbagunaBilling', 'kerjasama.ubahStatusBillingSatuan', 'kerjasama.ubahStatusBillingMassal',
      //   'kerjasama.flaggingBilingAdmisiMou', 'kerjasama.flaggingMouSatuan', 'kerjasama.flaggingMouMassal',
      //   'menuKhusus.aktivasiBillingBeasiswa', 'menuKhusus.aktivasiSatuan', 'menuKhusus.aktivasiMassal'
      // ]
      const notMenusSruh = [
        'kerjasama.entryBeasiswa',
        'kerjasama.importMahasiswaBeasiswa',
        'kerjasama.suratTagihanBiayaPendidikan',
        'kerjasama.dokumentasiSk',
        'kerjasama.serbagunaBilling',
        'kerjasama.ubahStatusBillingSatuan',
        'kerjasama.ubahStatusBillingMassal',
        'kerjasama.flaggingBilingAdmisiMou',
        'kerjasama.flaggingMouSatuan',
        'kerjasama.flaggingMouMassal',
        'sidebar.menuKhusus',
        'menuKhusus.aktivasiBillingBeasiswa',
        'menuKhusus.aktivasiSatuan',
        'menuKhusus.aktivasiMassal',
        'menuKhusus.verifikasi',
        'menuKhusus.verifikasiDokumen',
        'menuKhusus.pembatalanVerifikasi',
        'menuKhusus.manajemenUser'
      ]
      const notMenusUtDaerah = [
        'master.jenisPks',
        'master.groupMitra',
        'master.kategoriPks',
        'master.subKategoriPks',
        'kerjasama.dokumentasiSk',
        'menuKhusus.verifikasi',
        'menuKhusus.verifikasiDokumen',
        'menuKhusus.pembatalanVerifikasi',
        'menuKhusus.manajemenUser'
      ]
      const notMenusDaak = [
        'master.jenisPks',
        'master.groupMitra',
        'master.kategoriPks',
        'master.subKategoriPks',
        'kerjasama.suratTagihanBiayaPendidikan',
        'menuKhusus.verifikasi',
        'menuKhusus.verifikasiDokumen',
        'menuKhusus.pembatalanVerifikasi',
        'menuKhusus.manajemenUser'
      ]
      const notMenusKeuangan = [
        'master.jenisPks',
        'master.groupMitra',
        'master.kategoriPks',
        'master.subKategoriPks',
        'kerjasama.entryBeasiswa',
        'kerjasama.importMahasiswaBeasiswa',
        'kerjasama.dokumentasiSk',
        'kerjasama.serbagunaBilling',
        'kerjasama.ubahStatusBillingSatuan',
        'kerjasama.ubahStatusBillingMassal',
        'kerjasama.flaggingBilingAdmisiMou',
        'kerjasama.flaggingMouSatuan',
        'kerjasama.flaggingMouMassal',
        'sidebar.menuKhusus',
        'menuKhusus.aktivasiBillingBeasiswa',
        'menuKhusus.aktivasiSatuan',
        'menuKhusus.aktivasiMassal',
        'menuKhusus.verifikasi',
        'menuKhusus.verifikasiDokumen',
        'menuKhusus.pembatalanVerifikasi',
        'menuKhusus.manajemenUser'
      ]
      const notMenusPpbi = [
        'master.jenisPks',
        'master.groupMitra',
        'master.kategoriPks',
        'master.subKategoriPks',
        'kerjasama.entryBeasiswa',
        'kerjasama.importMahasiswaBeasiswa',
        'kerjasama.dokumentasiSk',
        'kerjasama.serbagunaBilling',
        'kerjasama.ubahStatusBillingSatuan',
        'kerjasama.ubahStatusBillingMassal',
        'kerjasama.flaggingBilingAdmisiMou',
        'kerjasama.flaggingMouSatuan',
        'kerjasama.flaggingMouMassal',
        'sidebar.menuKhusus',
        'menuKhusus.aktivasiBillingBeasiswa',
        'menuKhusus.aktivasiSatuan',
        'menuKhusus.aktivasiMassal',
        'menuKhusus.verifikasi',
        'menuKhusus.verifikasiDokumen',
        'menuKhusus.pembatalanVerifikasi',
        'menuKhusus.manajemenUser'
      ]
      if (MENU_ROLE.DAAK_DAN_KEUANGAN.includes(String(idUnitKs))) {
        console.log('Full Access')
        /** Full Access */
        this.items.map(a => {
          a.is_heading = false
          a.is_icon_class = true
        })
      } else {
        /** Access per Role */
        this.items.map(a => {
          // if (MENU_ROLE.DPKS.includes(String(idUnitKs))) {
          //   console.log('DPKS')
          //   /** DPKS */
          //   if (notMenusDpks.includes(a.name)) {
          //     a.is_heading = true
          //     a.is_icon_class = false
          //   } else {
          //     a.is_heading = false
          //     a.is_icon_class = true
          //   }
          // }
          if (MENU_ROLE.SRUH.includes(String(idUnitKs))) {
            console.log('SRUH')
            /** SRUH */
            if (notMenusSruh.includes(a.name)) {
              a.is_heading = true
              a.is_icon_class = false
            } else {
              a.is_heading = false
              a.is_icon_class = true
            }
          } else if (MENU_ROLE.UT_DAERAH.includes(String(idUnitKs))) {
            console.log('UT Daerah')
            /** UT Daerah */
            if (notMenusUtDaerah.includes(a.name)) {
              a.is_heading = true
              a.is_icon_class = false
            } else {
              a.is_heading = false
              a.is_icon_class = true
            }
          } else if (MENU_ROLE.DAAK.includes(String(idUnitKs))) {
            console.log('DAAK')
            /** DAAK */
            if (notMenusDaak.includes(a.name)) {
              a.is_heading = true
              a.is_icon_class = false
            } else {
              a.is_heading = false
              a.is_icon_class = true
            }
          } else if (MENU_ROLE.KEUANGAN.includes(String(idUnitKs))) {
            console.log('Keuangan')
            /** Keuangan */
            if (notMenusKeuangan.includes(a.name)) {
              a.is_heading = true
              a.is_icon_class = false
            } else {
              a.is_heading = false
              a.is_icon_class = true
            }
          } else if (MENU_ROLE.PPBI.includes(String(idUnitKs))) {
            console.log('PPBI')
            /** PPBI */
            if (notMenusPpbi.includes(a.name)) {
              a.is_heading = true
              a.is_icon_class = false
            } else {
              a.is_heading = false
              a.is_icon_class = true
            }
          } else if (MENU_ROLE.FULL_ACCESS.includes(String(idUnitKs))) {
            console.log('all access')
            a.is_heading = true
            a.is_icon_class = true
          } else {
            console.log('else')
            a.is_heading = false
            a.is_icon_class = false
          }
          /** old */
          // if (menus.includes(a.name)) {
          //   a.is_heading = true
          //   a.is_icon_class = false
          // } else {
          //   a.is_heading = false
          //   a.is_icon_class = true
          // }
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@media (min-width: 1300px) {
  .custom-sidebar {
    margin-left: -20px !important;
    padding-right: 12px !important;
    border-top-right-radius: 0.35rem !important;
    border-bottom-right-radius: 0.35rem !important;
  }
}
</style>
